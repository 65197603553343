import { Controller } from "@hotwired/stimulus"
import Swiper from 'swiper'
import { Navigation, Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

export default class extends Controller {
    connect() {
        const swiper = new Swiper(this.element, {
            modules: [Navigation, Pagination, Autoplay],
            lazy: true,
            navigation: {
                nextEl: '.frswiper-button-next',
                prevEl: '.frswiper-button-prev',
            },
            autoplay: {
                delay: 10000,
                disableOnInteraction: false,
                pauseOnMouseEnter: true,
            },
            pagination: {
                el: ".banner-swiper-pagination",
                clickable: true,
                bulletClass: "fr-swiper-pagination-bullet",
                bulletActiveClass: "fr-swiper-pagination-bullet-active"
            }
        })
    }
}
