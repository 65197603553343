// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers/frontend.js"


import hljs from 'highlight.js';
document.addEventListener('DOMContentLoaded', (event) => {
    document.querySelectorAll('pre > code').forEach((block) => {
        hljs.highlightBlock(block);
    });
});
